import React, { FC } from 'react';
import clsx from 'clsx';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import { HardOfferSummary } from 'handlers/applicationData';
import { RootState } from 'handlers';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';

import styles from './LoanSummary.module.scss';

export interface LoanSummaryProps extends HardOfferSummary {
  additionalFunds: number;
  totalDebt: number;
}

const LoanSummary: FC<LoanSummaryProps> = ({
  additionalFunds,
  totalDebt,
  moneySaved,
  monthsSaved,
  monthlyPaymentSaved,
}) => {
  const { isLoading } = useSelector((state: RootState) => state.applicationData);

  const saveMoney = {
    weight: moneySaved ?? 0,
    element: (
      <div className={styles.summaryCheck}>
        <CheckIcon className={styles.checkIcon} />
        <div className={styles.summaryTextContent}>
          <div className={styles.checkText}>Save money</div>
          <div className={styles.checkAmount}>{formatMonetaryAmount(moneySaved)}</div>
        </div>
      </div>
    ),
  };

  const reduceMonthlyPayment = {
    weight: (monthlyPaymentSaved ?? 0) * 12,
    element: (
      <div className={styles.summaryCheck}>
        <CheckIcon className={styles.checkIcon} />
        <div className={styles.summaryTextContent}>
          <div className={styles.checkText}>Reduce monthly payment</div>
          <div className={styles.checkAmount}>{formatMonetaryAmount(monthlyPaymentSaved)}</div>
        </div>
      </div>
    ),
  };

  const saveTime = {
    weight: monthsSaved ?? 0,
    element: (
      <div className={styles.summaryCheck}>
        <CheckIcon className={styles.checkIcon} />
        <div className={styles.summaryTextContent}>
          <div className={styles.checkText}>Save time</div>
          <div className={styles.checkAmount}>{monthsSaved} months</div>
        </div>
      </div>
    ),
  };

  const itemsToShow = (saveMoney.weight > 0 && saveTime.weight > 0
    ? [saveMoney, saveTime]
    : [saveMoney, saveTime, reduceMonthlyPayment]
  )
    .filter((item) => item.weight > 0)
    .sort((item1, item2) => item2.weight - item1.weight)
    .map((item) => item.element);

  if (!itemsToShow.length) {
    return <></>;
  }

  return (
    <div className={styles.loanSummary}>
      <div className={clsx(styles.summaryCard, styles.centerAligned)}>
        {isLoading ? (
          <Loader color="#9d86f9" size={68} />
        ) : (
          <div className={styles.summaryText}>
            {additionalFunds === 0 && totalDebt !== 0 && (
              <>
                Consolidate debt with your <span className={styles.highlighted}>{formatMonetaryAmount(totalDebt)}</span>{' '}
                loan
              </>
            )}
            {additionalFunds !== 0 && totalDebt === 0 && (
              <>
                Borrow <span className={styles.highlighted}>{formatMonetaryAmount(additionalFunds)}</span> with Plannery
              </>
            )}
            {additionalFunds !== 0 && totalDebt !== 0 && (
              <>
                Consolidate <span className={styles.highlighted}>{formatMonetaryAmount(totalDebt)}</span> and borrow{' '}
                <span className={styles.highlighted}>{formatMonetaryAmount(additionalFunds)}</span>
              </>
            )}
          </div>
        )}
      </div>
      <div className={styles.summaryCard}>{isLoading ? <Loader color="#9d86f9" size={68} /> : <>{itemsToShow}</>}</div>
    </div>
  );
};

export default LoanSummary;
